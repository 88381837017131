import React, { Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import useFetch from 'use-http';
import './app.scss';
import { Navbar } from './components/navbar/navbar';
import { Website } from './services/website-types';

const GeddesHouse = React.lazy(() => import('./screens/networks/geddes-house'));

const RouteComponent = React.lazy(
  () => import('./components/pages/screen-component')
);

export const App = () => {
  const options = {};

  const { loading, error, data } = useFetch<Website>(
    './assets/website.json',
    options,
    []
  );

  if (!data) return null;

  return (
    <Router>
      {/* <ScrollToTop /> */}
      <Switch>
        <Route path="/">
          <Suspense fallback={null}>
            <GeddesHouse />
          </Suspense>
        </Route>
      </Switch>
    </Router>
  );
};
