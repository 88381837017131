/* eslint-disable @typescript-eslint/ban-types */
/* istanbul ignore file */

import { v4 as uuidv4 } from 'uuid';

type ISegments = 'newsletter';
type IEvents = 'newsletterwelcome';


class CrispServiceDef {
  private $crisp;
  private readonly localStorageKey = 'medayouser';

  constructor() {
    if (window['startCrisp']) {
      if (typeof Storage !== 'undefined') {
        const getSupportId = localStorage.getItem(this.localStorageKey);

        if (getSupportId) {
          window['startCrisp'](getSupportId);
        } else {
          const makeId = uuidv4();
          localStorage.setItem(this.localStorageKey, makeId);
          window['startCrisp'](makeId);
        }
      } else {
        window['startCrisp']();
      }
    }
  }

  public isReady(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const interval = setInterval(() => {
        if (window['$crispReady']) {
          clearInterval(interval);
          clearTimeout(timeout);
          this.$crisp = window['$crisp'];
          resolve(true);
        }
      }, 100);

      const timeout = setTimeout(() => {
        reject(false);
        clearInterval(interval);
      }, 5000);
    });
  }

  public async setUserName(name: string) {
    await this.isReady();
    this.$crisp.push(['set', 'user:nickname', [name]]);
  }

  public async setUserEmail(email: string) {
    await this.isReady();
    this.$crisp.push(['set', 'user:email', [email]]);
  }

  public async triggerEvent(triggerEvent: IEvents) {
    await this.isReady();
    this.$crisp.push(['do', 'trigger:run', [triggerEvent]]);
  }

  public async recordEvent(
    eventName,
    data: object,
    color: CrispColorType = 'green'
  ) {
    await this.isReady();
    this.$crisp.push(['set', 'session:event', [[[eventName, data, color]]]]);
  }

  public async showMessage(type: 'text' | 'animation', text) {
    await this.isReady();
    this.$crisp.push(['do', 'message:show', [type, text]]);
  }

  public async sendMessage(message: string) {
    await this.isReady();
    this.$crisp.push(['do', 'message:send', ['text', message]]);
  }

  public async open() {
    await this.isReady();
    this.$crisp.push(['do', 'chat:show']);
    this.$crisp.push(['do', 'chat:open']);
  }

  public async close() {
    await this.isReady();
    this.$crisp.push(['do', 'chat:close']);
  }

  public async hide() {
    await this.isReady();
    this.$crisp.push(['do', 'chat:hide']);
  }

  public async move() {
    await this.isReady();
    const crispDivEl = document.querySelectorAll(
      'a[class*="crisp-"][href*="#!"]'
    );
    console.log(crispDivEl);
  }

  public async onClose(callback) {
    await this.isReady();
    this.$crisp.push(['off', 'chat:closed']);
    this.$crisp.push(['on', 'chat:closed', callback]);
  }

  public async onMessage(callback) {
    await this.isReady();
    this.$crisp.push(['off', 'message:received']);
    this.$crisp.push(['on', 'message:received', callback]);
  }

  public async setCompanyName(companyName: string) {
    await this.isReady();
    this.$crisp.push(['set', 'user:company', [companyName]]);
  }

  public async addSegment(name: ISegments) {
    await this.isReady();
    this.$crisp.push(["set", "session:segments", [[name]]]);
  }

  public async addSessionData(key: ISegments, value: string) {
    await this.isReady();
    this.$crisp.push(["set", "session:data", [[[key, value]]]]);
  }

  public async getSessionData(key: ISegments) {
    await this.isReady();
    console.log(this.$crisp.get("session:data", key));
    return this.$crisp.get("session:data", key);
  }


}
export const CrispService = new CrispServiceDef();

type CrispColorType =
  | 'red'
  | 'orange'
  | 'yellow'
  | 'green'
  | 'blue'
  | 'purple'
  | 'pink'
  | 'brown'
  | 'grey'
  | 'black';
