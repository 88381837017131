import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './app/app';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { environment } from './environments/environment';
import { CrispService } from './app/services/crisp/crisp.service';

CrispService.hide();

CrispService.onClose(() => CrispService.hide());

Sentry.init({
  dsn:
    'https://2f376f52528045d1ba2fed7f98c8c267@o876249.ingest.sentry.io/5871316',
  integrations: [new Integrations.BrowserTracing()],
  environment: environment.environment,
  tracesSampleRate: 1.0,
});

// (async () => await CrispService.isReady())();

ReactDOM.render(<App />, document.getElementById('root'));

declare global {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function JitsiMeetExternalAPI(domain: string, options: any): void;
}

const vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);

window.addEventListener('resize', () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});
